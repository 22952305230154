@font-face {
  font-family: 'custom-bold';
  src: url("/fonts/custom-bold.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'custom';
  src: url("/fonts/custom.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start; }

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit; }

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0; }

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch; }

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  visibility: visible;
  overflow: auto;
  /* Scroll on this element otherwise element can't have a padding applied properly */
  max-width: 100%;
  /* Not required for horizontal scroll to trigger */
  max-height: 100%;
  /* Needed for vertical scroll to trigger */ }

.simplebar-content:before,
.simplebar-content:after {
  content: " ";
  display: table; }

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none; }

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: inherit;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0; }

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1; }

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden; }

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all; }

.simplebar-scrollbar {
  position: absolute;
  right: 2px;
  width: 7px;
  min-height: 10px; }

.simplebar-scrollbar:before {
  position: absolute;
  content: "";
  background: black;
  border-radius: 7px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s linear; }

.simplebar-track .simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.5;
  transition: opacity 0s linear; }

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px; }

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px; }

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px; }

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px; }

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto; }

/* Rtl support */
[data-simplebar-direction="rtl"] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0; }

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll; }

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */ }

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px); }

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0); }

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none; }

.owl-carousel .owl-item img {
  display: block;
  width: 100%; }

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none; }

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit; }

.owl-carousel.owl-loaded {
  display: block; }

.owl-carousel.owl-loading {
  opacity: 0;
  display: block; }

.owl-carousel.owl-hidden {
  opacity: 0; }

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden; }

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab; }

.owl-carousel.owl-rtl {
  direction: rtl; }

.owl-carousel.owl-rtl .owl-item {
  float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out; }

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
			This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
			calculation of the height of the owl-item that breaks page layouts
		 */ }

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease; }

.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0; }

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d; }

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

@-webkit-keyframes rotate-play {
  100% {
    transform: rotate(360deg); } }

@-moz-keyframes rotate-play {
  100% {
    transform: rotate(360deg); } }

@-o-keyframes rotate-play {
  100% {
    transform: rotate(360deg); } }

@keyframes rotate-play {
  100% {
    transform: rotate(360deg); } }

html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

html, body {
  margin: 0;
  height: 100%;
  min-height: 100%;
  background: #A3A3A3; }

body {
  font-family: 'custom', Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 18px;
  line-height: 1.4; }

a {
  text-decoration: none; }

strong {
  font-family: 'custom-bold', Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-style: normal; }

h1 {
  font-size: 29px;
  margin: 0 0 20px 0; }

h2 {
  font-size: 20px;
  margin: 0 0 20px 0; }

h3 {
  font-size: 20px;
  margin: 0 0 20px 0; }

h4 {
  font-size: 20px;
  margin: 0 0 20px 0; }

h1 + h2 {
  margin-top: -16px; }

p {
  font-size: 18px;
  margin: 0 0 20px 0; }

ul {
  margin: 0;
  padding: 0; }

li {
  list-style: none; }

img.lazyload, img.lazyloading {
  opacity: 0; }

img.lazyloaded {
  opacity: 1;
  transition: opacity 300ms; }

img.lazyload:not([src]) {
  visibility: hidden; }

#wrapper {
  min-height: 100%;
  position: relative; }

.responsive-test {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  display: none;
  font-size: 10px; }
  @media (min-width: 540px) {
    .responsive-test {
      font-size: 11px; } }
  @media (min-width: 720px) {
    .responsive-test {
      font-size: 12px; } }
  @media (min-width: 960px) {
    .responsive-test {
      font-size: 13px; } }
  @media (min-width: 1140px) {
    .responsive-test {
      font-size: 14px; } }

.image-wrap {
  margin: 0 0 20px 0;
  position: relative;
  background: #2B2B2B; }
  .image-wrap:before {
    content: "";
    display: block;
    height: 0;
    width: 100%;
    padding-top: 80%; }
  .image-wrap > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .image-wrap .lazyload {
    z-index: 20;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .image-wrap .lazyload-bg {
    background-size: cover;
    background-position: center center; }
  .image-wrap a {
    display: block;
    z-index: 20;
    width: 100%; }
  .image-wrap .lazyloaded ~ .loader {
    opacity: 0;
    transition: opacity 300ms;
    animation: none; }

.hidden {
  display: none; }

.loader {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -13px;
  margin-left: -13px;
  display: block;
  width: 26px;
  height: 26px;
  opacity: 0.5;
  -webkit-animation: rotate-play 2s linear infinite;
  -moz-animation: rotate-play 2s linear infinite;
  -o-animation: rotate-play 2s linear infinite;
  animation: rotate-play 2s linear infinite; }
  .loader .icon {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 16px;
    height: 16px; }
    .loader .icon:before, .loader .icon:after {
      content: "";
      position: absolute;
      display: block;
      width: 16px;
      height: 1px;
      background: white;
      top: 50%;
      left: 0;
      margin-top: -0.5px;
      transform-origin: 50% 50%;
      transition: transform 300ms; }
    .loader .icon:before {
      transform: rotate(45deg); }
    .loader .icon:after {
      transform: rotate(-45deg); }

header {
  overflow: hidden;
  height: 100vh;
  /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  z-index: 400;
  width: 100%;
  background: rgba(0, 0, 0, 0.9);
  transition: width 300ms ease-out, height 300ms ease-out; }
  .header-compact header {
    height: 40px; }
    @media (min-width: 720px) {
      .header-compact header {
        height: 100vh;
        /* Fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 1vh) * 100);
        width: 112px; } }
  @media (min-width: 720px) {
    header {
      width: 510px; } }
  header > .inner {
    height: 100vh;
    /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    margin: 0 18px; }
    @media (min-width: 720px) {
      header > .inner {
        width: 510px; } }
  header #logo {
    font-family: 'custom', Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 22px;
    line-height: 40px;
    position: absolute;
    display: block;
    padding: 0 18px;
    color: white; }
    @media (min-width: 720px) {
      header #logo {
        position: absolute;
        top: 10px;
        left: 28px;
        padding: 0;
        line-height: 1.1;
        font-size: 44px; }
        header #logo span {
          display: block;
          position: relative;
          left: 80px;
          width: 184px;
          height: 88px;
          transform-origin: 0% 0%;
          transform: rotate(90deg); } }
    header #logo:hover {
      text-decoration: none; }

.header-nav {
  position: absolute;
  bottom: 40px;
  left: 0; }
  .header-nav ul {
    padding: 0;
    margin: 0;
    position: relative;
    transition: left 300ms ease-out; }
    @media (min-width: 720px) {
      .header-nav ul {
        left: 0; }
        .header-compact .header-nav ul {
          left: -260px; } }
    .header-nav ul li {
      list-style: none;
      padding: 0;
      margin: 0;
      display: block;
      width: 100%; }
      .header-nav ul li.active a {
        color: white; }
      .header-nav ul li a {
        padding: 1px 0;
        display: inline-block;
        text-transform: lowercase;
        color: #FF0000;
        text-decoration: none;
        cursor: pointer;
        -webkit-touch-callout: none;
        /* iOS Safari */
        -webkit-user-select: none;
        /* Safari */
        -khtml-user-select: none;
        /* Konqueror HTML */
        -moz-user-select: none;
        /* Firefox */
        -ms-user-select: none;
        /* Internet Explorer/Edge */
        user-select: none;
        -webkit-tap-highlight-color: transparent;
        outline: none; }
        .header-nav ul li a:hover, .header-nav ul li a.active {
          color: white; }

.header-info {
  color: white;
  width: 260px;
  position: absolute;
  top: 65px;
  bottom: 160px; }
  .header-info .scrolling {
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: left 300ms ease-out; }
    @media (min-width: 720px) {
      .header-info .scrolling {
        left: 0; }
        .header-compact .header-info .scrolling {
          left: -275px; } }
    .header-info .scrolling section {
      display: none; }
      .header-info .scrolling section.active {
        display: block; }
  .header-info .scrolling-inner {
    margin-right: 16px; }
  .header-info .simplebar-track {
    opacity: 1; }
    .header-info .simplebar-track .simplebar-scrollbar:before {
      background: rgba(255, 255, 255, 0.5); }
  @media (min-width: 720px) {
    .header-info {
      top: 225px;
      bottom: 160px; } }
  .header-info a {
    color: white;
    text-decoration: none; }
    .header-info a:hover {
      color: #F00; }

.navbar-toggler {
  position: absolute;
  top: 2px;
  right: 5px; }
  @media (min-width: 720px) {
    .navbar-toggler {
      top: auto;
      right: 10px;
      bottom: 10px; } }
  .header-compact .navbar-toggler .icon:before {
    transform: rotate(180deg); }
  .header-compact .navbar-toggler .icon:after {
    transform: rotate(-270deg); }

.toggler {
  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  display: block;
  width: 36px;
  height: 36px;
  text-indent: -300em;
  overflow: hidden; }
  .toggler:focus {
    outline: none; }
  .toggler .icon {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 16px;
    height: 16px; }
    @media (min-width: 720px) {
      .toggler .icon {
        width: 24px;
        height: 24px; } }
    .toggler .icon:before, .toggler .icon:after {
      content: "";
      position: absolute;
      display: block;
      width: 16px;
      height: 1px;
      background: white;
      top: 50%;
      left: 0;
      margin-top: -0.5px;
      transform-origin: 50% 50%;
      transition: transform 300ms; }
      @media (min-width: 720px) {
        .toggler .icon:before, .toggler .icon:after {
          width: 24px; } }
    .toggler .icon:before {
      transform: rotate(45deg); }
    .toggler .icon:after {
      transform: rotate(-45deg); }

#lightbox {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  right: 0;
  bottom: 0;
  z-index: 1000;
  transition: opacity 0.4s, transform 0s;
  transform: translate(0px); }
  #lightbox.hidden {
    display: block;
    transform: translate(9999px);
    transition: opacity 0.4s, transform 0s linear 0.4s;
    opacity: 0; }
  #lightbox .close-lightbox {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 2; }
  #lightbox .content {
    position: relative;
    z-index: 1; }
  #lightbox .content, #lightbox .owl-carousel, #lightbox .owl-stage-outer, #lightbox .owl-stage, #lightbox .owl-item, #lightbox .slide {
    height: 100%; }
  #lightbox .image-wrap {
    background: none;
    height: 100%;
    width: 100%;
    margin: 0; }
    #lightbox .image-wrap:before {
      height: auto;
      padding: 0;
      display: none; }
    #lightbox .image-wrap .lazyload-bg {
      background-size: contain;
      background-repeat: no-repeat; }
  #lightbox .owl-stage-outer {
    z-index: 1; }
  #lightbox .owl-dots {
    z-index: 2;
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
    user-select: none; }
    #lightbox .owl-dots .owl-dot {
      display: block;
      float: left;
      width: 18px;
      height: 24px;
      position: relative;
      -webkit-touch-callout: none;
      /* iOS Safari */
      -webkit-user-select: none;
      /* Safari */
      -khtml-user-select: none;
      /* Konqueror HTML */
      -moz-user-select: none;
      /* Firefox */
      -ms-user-select: none;
      /* Internet Explorer/Edge */
      user-select: none;
      -webkit-tap-highlight-color: transparent;
      outline: none; }
      #lightbox .owl-dots .owl-dot:focus {
        outline: none; }
      #lightbox .owl-dots .owl-dot span {
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -4px;
        margin-top: -4px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: transparent;
        border: 1px solid white;
        transition: background-color 0.5s; }
      #lightbox .owl-dots .owl-dot.active span {
        background-color: white; }

main {
  height: calc(100% - 40px);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: #A3A3A3;
  top: 40px;
  overflow: hidden;
  z-index: 200; }
  @media (min-width: 720px) {
    main {
      height: 100%;
      top: 0;
      left: 112px; } }
  main:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 1600px;
    width: 1000px;
    background: #8D8D8D;
    z-index: 300;
    box-shadow: -5px 0 5px rgba(0, 0, 0, 0.2); }

.columns-container {
  z-index: 80;
  flex-basis: auto;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  max-width: 1600px; }
  @media (min-width: 720px) {
    .columns-container {
      flex-direction: row;
      white-space: nowrap;
      display: block;
      height: 100%; } }

.column {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  z-index: 3;
  transition: min-height 1s;
  box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.2);
  min-height: 0px;
  max-height: 90vh;
  position: relative;
  overflow: visible;
  background: white;
  vertical-align: top; }
  .column.active {
    min-height: 100%; }
    @media (min-width: 720px) {
      .column.active {
        min-height: 0; } }
  @media (min-width: 720px) {
    .column {
      position: relative;
      display: inline-block;
      height: 100%;
      overflow: hidden;
      transition: width 0.7s ease-out;
      min-height: 0;
      max-height: none;
      box-shadow: -5px 0 5px rgba(0, 0, 0, 0.2); }
      .fast-transition .column {
        transition: width 0.3s ease-out; } }
  @media (min-width: 720px) {
    .column:last-child, .column.active {
      width: 383px; } }
  @media (min-width: 960px) {
    .column:last-child, .column.active {
      width: 540px; } }
  .column:before {
    z-index: 2;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: -1px;
    width: 2px;
    bottom: 0;
    background: white; }
  .column:after {
    pointer-events: none; }
    @media (min-width: 720px) {
      .column:after {
        z-index: 1;
        content: "";
        position: absolute;
        top: 230px;
        left: 10px;
        right: 0;
        bottom: 0;
        opacity: 0.8;
        transition: opacity 1s; } }
  .column.active:after {
    opacity: 0; }
  .column:nth-child(1) {
    background: #F0F0F0; }
    .column:nth-child(1):before {
      background: #F0F0F0; }
    .column:nth-child(1):after {
      background: #F0F0F0; }
  .column:nth-child(2) {
    background: #E0E0E0; }
    .column:nth-child(2):before {
      background: #E0E0E0; }
    .column:nth-child(2):after {
      background: #E0E0E0; }
  .column:nth-child(3) {
    background: #D1D1D1; }
    .column:nth-child(3):before {
      background: #D1D1D1; }
    .column:nth-child(3):after {
      background: #D1D1D1; }
  .column:nth-child(4) {
    background: #C2C2C2; }
    .column:nth-child(4):before {
      background: #C2C2C2; }
    .column:nth-child(4):after {
      background: #C2C2C2; }
  .column:nth-child(5) {
    background: #B3B3B3; }
    .column:nth-child(5):before {
      background: #B3B3B3; }
    .column:nth-child(5):after {
      background: #B3B3B3; }
  .column:nth-child(6) {
    background: #A3A3A3; }
    .column:nth-child(6):before {
      background: #A3A3A3; }
    .column:nth-child(6):after {
      background: #A3A3A3; }
  .column h1 {
    color: white;
    margin: 0;
    font-family: 'custom', Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 100px;
    line-height: 100px; }
    @media (min-width: 720px) {
      .column h1 {
        font-size: 130px;
        line-height: 130px; } }
  .column > .inner {
    overflow: hidden;
    vertical-align: baseline;
    pointer-events: none;
    padding: 0 0 0 12px; }
    @media (min-width: 720px) {
      .column > .inner {
        pointer-events: all; } }
    @media (min-width: 720px) {
      .column > .inner {
        padding: 0 22px 0 18px;
        min-width: 383px; } }
    @media (min-width: 960px) {
      .column > .inner {
        padding: 0 22px 0 18px;
        min-width: 540px; } }
  .column.active > .inner {
    pointer-events: all; }
  .column.active .header {
    border-bottom: 1px solid white; }

.col-header {
  white-space: normal;
  padding: 0 0 0 0;
  position: relative;
  overflow: hidden;
  transition: border 1s;
  border-bottom: 1px solid transparent;
  min-height: 103px; }
  .active .col-header {
    border-bottom: 1px solid white; }
  @media (min-width: 720px) {
    .col-header {
      border-bottom: 1px solid white;
      padding: 0 0 10px 0;
      min-height: 145px; } }
  @media (min-width: 960px) {
    .col-header {
      min-height: 215px; } }
  .col-header .image-wrap {
    position: absolute;
    transition: right 1s;
    cursor: pointer;
    overflow: hidden;
    top: 0;
    right: -55px;
    width: 55px; }
    @media (min-width: 720px) {
      .col-header .image-wrap {
        transition: none; } }
    .col-header .image-wrap .lazyload-bg {
      transition: opacity 0.2s ease-out; }
    .col-header .image-wrap:hover .lazyload-bg {
      opacity: 0.8; }
    .col-header .image-wrap:before {
      height: 102px; }
    .active .col-header .image-wrap {
      right: 0; }
    @media (min-width: 720px) {
      .col-header .image-wrap {
        top: 15px;
        right: 0px;
        width: 120px; }
        .col-header .image-wrap:before {
          height: 0; }
        .active .col-header .image-wrap {
          right: 0px; } }
    @media (min-width: 960px) {
      .col-header .image-wrap {
        top: 15px;
        right: 0px;
        width: 190px; } }
    .col-header .image-wrap:before {
      padding-top: 100%; }
    .col-header .image-wrap .lazyload-bg {
      filter: gray;
      /* IE6-9 */
      -webkit-filter: grayscale(1);
      /* Google Chrome, Safari 6+ & Opera 15+ */
      filter: grayscale(1);
      /* Microsoft Edge and Firefox 35+ */ }
    .col-header .image-wrap .toggler {
      position: absolute;
      bottom: 0px;
      right: 0px;
      display: none;
      top: auto;
      left: auto; }
      @media (min-width: 720px) {
        .col-header .image-wrap .toggler {
          display: block;
          bottom: 5px;
          right: 5px; } }
      .col-header .image-wrap .toggler .icon:before {
        transform: rotate(180deg); }
      .col-header .image-wrap .toggler .icon:after {
        transform: rotate(-270deg); }
  .col-header .details {
    font-family: 'custom', Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-style: normal;
    color: #262626;
    margin: 0;
    line-height: 1.2;
    position: absolute;
    left: 70px;
    top: 15px;
    width: 240px;
    font-size: 14px; }
    @media (min-width: 720px) {
      .col-header .details {
        font-size: 16px;
        position: relative;
        width: 270px;
        height: 76px;
        top: 0;
        transition: left 1s ease-out;
        left: -270px; }
        .active .col-header .details {
          left: 0px; } }
    .col-header .details > span {
      display: block; }
      .col-header .details > span.status.live {
        color: #F00; }
      .col-header .details > span.dates {
        margin-bottom: 4px; }

.col-content {
  white-space: normal;
  margin-top: 0;
  position: absolute;
  top: 118px;
  left: 12px;
  right: 18px;
  bottom: 60px;
  overflow: hidden;
  color: #262626;
  font-size: 13px; }
  @media (min-width: 720px) {
    .col-content {
      font-size: 15px;
      width: 344px;
      top: 238px;
      right: auto;
      left: 18px;
      bottom: 26px; } }
  @media (min-width: 960px) {
    .col-content {
      width: 500px; } }
  .col-content .scrolling {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: auto; }
  .col-content .scrolling-inner {
    margin-right: 16px; }
  .col-content .simplebar-track {
    opacity: 0; }
    .active .col-content .simplebar-track {
      transition: opacity 0.5s 1s;
      opacity: 1; }
  .col-content .message {
    margin-bottom: 20px;
    color: #4B4B4B; }
  .col-content .line {
    font-size: 16px;
    line-height: 1.3; }
  .col-content .date {
    font-size: 13px;
    margin-top: 2px; }
  .col-content .msg {
    color: #F00;
    text-transform: lowercase; }
  .col-content .load-more {
    color: black;
    display: inline-block;
    margin-bottom: 50px;
    text-transform: uppercase;
    font-size: 14px;
    position: relative; }
    .col-content .load-more .loader {
      opacity: 0;
      transition: opacity 300ms;
      left: 0;
      position: absolute;
      margin-left: -5px;
      top: 8px; }
      .col-content .load-more .loader .icon:before, .col-content .load-more .loader .icon:after {
        background: black; }
    .loading .col-content .load-more {
      text-indent: -300em; }
      .loading .col-content .load-more .loader {
        opacity: 0.5; }

.simplebar-scrollbar:before {
  background: rgba(0, 0, 0, 0.5); }

.col-toggler {
  position: absolute;
  right: 10px;
  bottom: 10px;
  opacity: 0;
  pointer-events: none; }
  @media (min-width: 720px) {
    .col-toggler {
      display: none; } }
  .col-toggler .icon {
    width: 24px;
    height: 24px; }
    .col-toggler .icon:before, .col-toggler .icon:after {
      width: 24px;
      background: black; }
  .column.active .col-toggler {
    pointer-events: all;
    transition: opacity 1s;
    opacity: 1; }
